import React, { Component } from "react";
import BlockTemplate from "../blocktemplate";
import Portfolio from "../../icons/portfolio";

class Experience extends Component {
  render() {
    return (
      <BlockTemplate
        className="miscellaneous"
        header="Miscellaneous"
        icon={Portfolio}
        partial
      >
        <ul>
          <li>
            Active developer consistently attending meetups, conferences,
            workshops and commiting code daily.
          </li>
          <li>Ran Free Code Camp in Concord New Hampshire</li>
          <li>TESOL & CELTA Teaching Certifications</li>
        </ul>
      </BlockTemplate>
    );
  }
}

export default Experience;
