import React, { Component } from "react";
import BlockTemplate from "../blocktemplate";
import Coding from "../../icons/coding";
import { Circle } from "rc-progress";
import styled from "styled-components";
import { SkillsList } from "../../page-data";
import Popover from "react-tiny-popover";

const SkillList = styled.ul`
  display: flex;
  padding: 0;
  margin: 1em 0 0 0;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;
const Skill = styled.li`
  width: 100px;
  list-style: none;
  text-align: center;
  margin-right: 1.5em;
  span > span {
    position: relative;
    bottom: 65px;
  }
`;
const Progress = styled(Circle)`
  width: 100px;
`;

const SkillDetails = styled.div`
  margin: 0;
  font-weight: 400;
  padding: 1rem;
  border-right: 5px solid #006c94;
  background: #303036;
  max-width: 500px;
  color: white;
`;

class Skills extends Component {
  state = {};

  render() {
    return (
      <BlockTemplate className="Skills" header="Skills" icon={Coding}>
        <SkillList>
          {Object.keys(SkillsList).map((skill, keyIndex) => {
            return (
              <Popover
                key={skill}
                isOpen={this.state[skill]}
                position={"top"}
                content={
                  <SkillDetails>{SkillsList[skill].details}</SkillDetails>
                }
              >
                <Skill
                  onMouseEnter={() =>
                    this.setState({
                      [skill]: true
                    })
                  }
                  onMouseLeave={() =>
                    this.setState({
                      [skill]: false
                    })
                  }
                >
                  <span>
                    <Progress
                      percent={SkillsList[skill].level}
                      strokeWidth="4"
                      trailColor="#fff"
                      strokeColor="#006c94"
                    />
                    <span>{SkillsList[skill].name}</span>
                  </span>
                </Skill>
              </Popover>
            );
          })}
        </SkillList>
      </BlockTemplate>
    );
  }
}

export default Skills;
