export const Jobs = {
  vitalsource: {
    name: "VitalSource",
    url: "https://www.VitalSource.com",
    title: "Software Engineer",
    dateRange: "2016 - Present",
    description: "jobs.vitalsource"
  },
  rementor: {
    name: "RE Mentor",
    url: "https://rementor.com/",
    title: "Web Developer",
    dateRange: "2014 - 2016",
    description: "jobs.rementor"
  },
  tapmojo: {
    name: "TapMojo",
    url: "https://en.wikipedia.org/wiki/GifBoom",
    title: "Social Media Manager",
    dateRange: "2011-2012",
    description: "jobs.tapmojo"
  }
};

export const education = {
  42: {
    name: "42 Code School Piscine",
    url: "https://www.42.us.org/",
    focus: "C Language",
    dateRange: "2016 - Present",
    description:
      "42 is a private, nonprofit and tuition-free computer programming school created and funded by French billionaire Xavier Niel (founder of the telecommunication company Illiad). Attended piscine C coding school in Fremont California."
  },
  generalassembly: {
    name: "General Assembly",
    url: "https://generalassemb.ly",
    focus: "Software Development",
    dateRange: "2015 - 2016",
    description:
      "General Assembly is a private, for-profit education organization founded in early 2011. Attended Web Development coding boot camp. Learned and wrote practical web development best-practices."
  },
  plymouth: {
    name: "Plymouth State Univerity",
    url: "https://www.plymouth.edu/",
    focus: "English Film & Literature",
    dateRange: "2011 - 2012",
    description:
      "B.A. In English literature. My studies have provided me with a wide array of written and oral communication skills. As an english major; One studies presenting clear ideas, the art of constructing well-rounded arguments, and providing background information to support said arguments."
  }
};

export const SkillsList = {
  js: {
    name: "ES6",
    level: "93",
    details: "Main language used at currently held position"
  },
  react: {
    name: "React JS",
    level: "90",
    details: "Main framwork used at currently held position"
  },
  html: {
    name: "HTML",
    level: "95",
    details:
      "In addition to knowing HTML/HTML5, I also do extensive work with website accessability."
  },
  css: {
    name: "CSS",
    level: "95",
    details:
      "CSS is a core part of the front-end. I use css daily and its many variants as well: sass, scss, styled-components, etc."
  },
  bootstrap: {
    name: "Bootstrap",
    level: "95",
    details:
      "I've used bootstrap 3 and 4 on a variety of professional and personal projects"
  },
  wordpress: {
    name: "WordPress",
    level: "70",
    details:
      "I have experience implementing wordpress: templates, themes, and plugins. I've built sites wordpress with shopping carts, users with varying roles/subscriptions, and have experience hosting and modifying core WP files."
  },
  jquery: {
    name: "jQuery",
    level: "90",
    details:
      "Used a lot of jQuery at previous job. We coded a lot of things out using jQuery and vanilla js without the use of front-end frameworks."
  },
  git: {
    name: "git",
    level: "95",
    details:
      "I use git professionally and for my personal projects. As part of my job I do code reviews, pull requests, open issues, and more."
  },
  ruby: {
    name: "Ruby",
    level: "75",
    details:
      "Ruby on Rails is the first language and framework that I learned. I build personal projects using rails and work with it professionally as well."
  },
  php: {
    name: "PHP",
    level: "65",
    details:
      "One of my first dev jobs was hand-coding php websites to sell products. We didn't use frameworks, and did a lot of live code-editing through ftp. Admittedly it wasn't 'best practice', but we got the code deployed, made large code base improvements, sold products, and contributed to company growth."
  },
  analytics: {
    name: "Analytics",
    level: "55",
    details:
      "I've done a fair amount of work with tools such as google analytics, Kissmetrics, Facebook Ads, and hotjar. Some of these tasks included, but were not limited to: looking at clickrates, tracking where users are coming from, and product funnels."
  }
};

export const Education = {
  42: {
    name: "42 Code School Piscine",
    url: "https://www.42.us.org/",
    focus: "C Language",
    dateRange: "2016 - Present",
    description:
      "42 is a private, nonprofit and tuition-free computer programming school created and funded by French billionaire Xavier Niel (founder of the telecommunication company Illiad). Attended piscine C coding school in Fremont California."
  },
  generalassembly: {
    name: "General Assembly",
    url: "https://generalassemb.ly",
    focus: "Software Development",
    dateRange: "2015 - 2016",
    description:
      "General Assembly is a private, for-profit education organization founded in early 2011. Attended Web Development coding boot camp. Learned and wrote practical web development best-practices."
  },
  plymouth: {
    name: "Plymouth State Univerity",
    url: "https://www.plymouth.edu/",
    focus: "English Film & Literature",
    dateRange: "2011 - 2012",
    description:
      "B.A. In English literature. My studies have provided me with a wide array of written and oral communication skills. As an english major; One studies presenting clear ideas, the art of constructing well-rounded arguments, and providing background information to support said arguments."
  }
};

export const Hobbies = {
  aquascaping: {
    description:
      "...Freshwater aquascape Aquascaping is the craft of arranging aquatic plants, as well as rocks, stones, cavework, or driftwood, in an aesthetically pleasing manner within an aquarium—in effect, gardening under water."
  },
  powerLifting: {
    description:
      "<p>I enjoy fitness and work out regularly when I am not coding. I currently hold the Men's 148lbs weight-class squat and Deadlift record in the <a href='http://epfrecords.com/National_MEN.html'> Elite Power Lifting Federation </a> . In addition, I still retain the Men's junior squat, bench, and deadlift records. </p>"
  },
  travelling: {
    description:
      "<p>Lived in multiple districts within Tianjin, China for 3 years. While there I primarily worked, traveled, enjoyed the culture, and studied code. I've traveled to a handful of other places and hope to discover more destinations. </p>"
  },
  fishkeeping: {
    description:
      "<p>Currently maintain four aquariums ranging from 3 gallons up to 75 gallons. Some of my favorite fish I am currently caring for are: <ul> <li> <a href='https://en.wikipedia.org/wiki/Discus_(fish)'> Symphysodon - Discus </a> </li> <li> <a href='https://www.seriouslyfish.com/species/tetraodon-lineatus/'> Tetraodon lineatus - Fahaka Puffer </a> </li> <li> <a href='https://en.wikipedia.org/wiki/Aulonocara'></a> Aulonocara - Peacock Cichlid </li> <li>And many more!</li> </ul> </p>"
  }
};

export const Links = {
  somatic: {
    name: "Somatic",
    href: "https://www.somatic.io",
    description:
      "Worked Primarily on font-end with a few contributions towards Stripe API integration."
  },
  vessentials: {
    name: "The Vessentials",
    href: "https://www.thevessentials.io",
    description:
      "Built full project on rails 5. Stripe API integration, shopping cart, rails mailer and user account creation."
  },
  joshuatoy: {
    name: "Joshua Toy",
    href: "www.joshuatoy.com",
    description:
      "Website currently being read. This page was created using React Js."
  },
  greatdocs: {
    name: "The Great Docs",
    href: "https://greatdocs.netlify.com/",
    description: "Toy project using vanilla javascript, jQuery, html, and css."
  },
  balern: {
    name: "Balern Education",
    href: "http://balern.herokuapp.com/",
    description:
      "Now defunct English School in Tianjin, China. Rails 4 application using Bootstrap 3 on the front- end."
  },
  dictator: {
    name: "The Dictator",
    href: "https://dictionary-rmgb.netlify.com/",
    description:
      "Dictionary-like test project using a range of API calls and the <a href='https://masonry.desandro.com/'>Masonry</a> library"
  },
  mickey: {
    name: "Mickey Cunliffe",
    href: "https://mickeycunliffe.netlify.com/",
    description: "Concept site just using bootstrap, js, html, css."
  },
  mortyology: {
    name: "Mortyology",
    href: "https://toymechqm.github.io/mortyology/",
    description: "Rick & Morty themed astrology React app using"
  }
};
