import { Button } from "@vitalsource/vstui";
import React, { Component } from "react";
import styled from "styled-components";

const Nude = styled(Button)`
  margin-left: 0;
  padding-left: 0;
  margin-right: 0;
  padding-right: 0;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  color: #34495e;
`;

class NakedButton extends Component {
  render() {
    const { href, children } = this.props;
    return (
      <Nude
        naked
        small
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        className="strong"
      >
        {children}
      </Nude>
    );
  }
}

export default NakedButton;
