import React, { Component } from "react";
import styled from "styled-components";
import LinkedIn from "../../icons/linkedin";
import Email from "../../icons/envelope";
import GitHub from "../../icons/github";

const Footer = styled.footer`
  text-align: center;
  margin-top: auto;
  margin-bottom: 1em;
`;

const FollowMe = styled.div`
  margin: 2em 0 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 1em;
    * {
      fill: white;
    }
  }
`;

const Languages = {
  en: { name: "English" },
  es: { name: "Español" },
  zn: { name: "中文" },
  lt: { name: "L£et" },
  pl: { name: "igpay latin" }
};

const LocaleButton = styled.button`
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0px;
  color: #fff;
  font-family: Open Sans, sans-serif;
`;

class FooterOptions extends Component {
  state = { locale: "en" };
  render() {
    return (
      <Footer>
        <FollowMe>
          <LinkedIn width="75px" height="75px" />
          <GitHub width="90px" />
          <Email width="75px" height="75px" />
        </FollowMe>
        <small>
          {Object.keys(Languages).map((key, i) => {
            return (
              <LocaleButton onClick={() => this.props.changeLocale(key)}>
                {Languages[key].name}
              </LocaleButton>
            );
          })}
        </small>
      </Footer>
    );
  }
}

export default FooterOptions;
