import React from "react";
import { Jobs as JobHistory } from "../../page-data";
import BlockTemplate from "../blocktemplate";
import Portfolio from "../../icons/portfolio";
import NakedButton from "../naked-button";
import { Provider, Translate, Translator } from "react-translated";

const Experience = () => {
  return (
    <BlockTemplate className="Experience" header="Experience" icon={Portfolio}>
      <ul className="left-border">
        {Object.keys(JobHistory).map((job, i) => {
          const currentJob = JobHistory[job];
          return (
            <li key={currentJob.name}>
              <NakedButton href={currentJob.url}>{currentJob.name}</NakedButton>
              <div>{currentJob.dateRange}</div>
              <div>{currentJob.title}</div>
              <Translate text={`jobs.${job}`} />
            </li>
          );
        })}
      </ul>
    </BlockTemplate>
  );
};

export default Experience;
