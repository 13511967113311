import React, { Component } from "react";
import styled from "styled-components";
import BlockTemplate from "../blocktemplate";
import Adventurer from "../../icons/adventurer";
import Popover from "react-tiny-popover";
import { Button } from "@vitalsource/vstui";
import { Hobbies as HobbiesList } from "../../page-data";

const HobbyButton = styled(Button)`
  color: #34495e;
  font-size: 16px;
`;

const Modal = styled.div`
  margin: 0;
  font-weight: 400;
  padding: 1rem;
  background: #303036;
  border-right: 5px solid #3498db;
  max-width: 500px;
  color: #dcdde1;
  a {
    text-decoration: inherit;
    color: #dcdde1;
  }
`;

const Content = popoverData => {
  return <Modal dangerouslySetInnerHTML={{ __html: popoverData }} />;
};

class Hobbies extends Component {
  state = { fishkeeping: false, aquascaping: false };
  render() {
    return (
      <BlockTemplate
        className="Hobbies"
        header="Hobbies"
        icon={Adventurer}
        partial
      >
        {Object.keys(HobbiesList).map((hobby, index) => {
          return (
            <Popover
              isOpen={this.state[hobby]}
              key={index}
              position={"top"}
              content={Content(HobbiesList[hobby].description)}
            >
              <HobbyButton
                naked
                onClick={() =>
                  this.setState({
                    [hobby]: true
                  })
                }
                onMouseLeave={() =>
                  this.setState({
                    [hobby]: false
                  })
                }
              >
                {hobby}
              </HobbyButton>
            </Popover>
          );
        })}
      </BlockTemplate>
    );
  }
}

export default Hobbies;
