import React, { Component } from "react";
import styled from "styled-components";

const Header = styled.div`
  display: flex;
  padding-top: 18px;
  h2 {
    margin-top: 8px;
    margin-left: 10px;
  }
  .icon {
    background: #ff5964;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 60%;
      * {
        stroke: #dcdde1;
        fill: #dcdde1;
      }
    }
  }
`;

class BlockHeader extends Component {
  render() {
    const { header, icon } = this.props;

    return (
      <Header>
        {icon ? <div className="icon">{icon()}</div> : null}
        <h2>{header}</h2>
      </Header>
    );
  }
}

export default BlockHeader;
