import React, { Component } from "react";
import styled from "styled-components";
import BlockHeader from "../blockheader";
import MediaQuery from "react-responsive";

const Column = styled.div`
  background: #dcdde1;
  padding: 1em;
  margin: 1em 0;
  ${props => (props.partial ? "width: 45%; flex-grow: 1;" : "width: 100%")};
  .left-border {
    list-style: none;
    padding: 0;
    li {
      margin: 1em 0;
      padding-left: 1em;
      border-left: 5px solid white;
    }
  }
`;

const MobileColumn = styled.div`
  background: #dcdde1;
  padding: 1em;
  margin: 1em 0;
  .left-border {
    list-style: none;
    padding: 0;
    li {
      margin: 1em 0;
      padding-left: 1em;
      border-left: 5px solid white;
    }
  }
`;

class BlockTemplate extends Component {
  render() {
    const { icon, header, children, width, partial } = this.props;
    return (
      <React.Fragment>
        <MediaQuery maxWidth={640}>
          <MobileColumn width={width} className="data-block" partial={partial}>
            <BlockHeader header={header} icon={icon} />
            {children}
          </MobileColumn>
        </MediaQuery>
        <MediaQuery minWidth={640}>
          <Column width={width} className="data-block" partial={partial}>
            <BlockHeader header={header} icon={icon} />
            {children}
          </Column>
        </MediaQuery>
      </React.Fragment>
    );
  }
}

export default BlockTemplate;
