import React from "react";
import { Education as EducationHistory } from "../../page-data";
import BlockTemplate from "../blocktemplate";
import EducationIcon from "../../icons/education";

const Education = () => {
  const History = EducationHistory;
  return (
    <BlockTemplate
      className="Education"
      header="Education"
      icon={EducationIcon}
    >
      <ul className="left-border">
        {Object.keys(History).map((job, i) => {
          const education = History[job];
          return (
            <li key={education.name}>
              <a className="strong" href={education.url}>
                {education.name}
              </a>
              <div>{education.dateRange}</div>
              <div>{education.focus}</div>
              <div>{education.title}</div>
              <div>{education.description}</div>
            </li>
          );
        })}
      </ul>
    </BlockTemplate>
  );
};

export default Education;
