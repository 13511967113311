// translation.js

export default {
  jobTitle: {
    en: "Software Engineer",
    pl: "Oftwaresay Engineeryay!",
    zn: "软件工程师",
    lt: "Software €ngi|1eer",
    es: "Ingeniero de software"
  },
  bio: {
    en:
      "Frontend software engineer at Vitalsource Technologies. Specializations in React JS, javascript, and just about anything that goes into a browser. Web artisan: determined to decrease markup, compose consistent styles, and design elegant functional code for long-term product stability.",
    pl:
      "ontendfray oftwaresay engineeryay atyay Vitalsource Technologies. ecializationsspay inyay eactray sjay, avascriptjay, andyay ustjay aboutyay anythingyay atthay oesgay intoyay ayay owserbray. Ontemporarycay artisanyay eterminedday otay ecreaseday arkupmay , incorporateyay onsistentcay esstylay andyay oducepray elegantyay unctionalfay odecay orfay ong-termlay oductpray abilitystay.",
    zn:
      "Vitalsource Technologies的前端软件工程师。 React JS，javascript以及浏览器中的任何内容的专业化。当代工匠决心减少加价，采用一致的款式，并产生优雅的功能代码，以实现长期的产品稳定性。",
    lt:
      "f20n73nd 50f7w423 3n91n332 47 v174150u2c3 73chn0109135. 5p3c141124710n5 1n 234c7 j5, j4v45c21p7, 4nd ju57 480u7 4ny7h1n9 7h47 9035 1n70 4 820w532. c0n73mp0242y 427154n d3732m1n3d 70 d3c23453 m42kup, 1nc02p02473 c0n51573n7 57y135 4nd p20duc3 31394n7 func710n41 c0d3 f02 10n9-732m p20duc7 57481117y.",
    es:
      "Ingeniero de software frontend en Vitalsource Technologies. Especializaciones en React JS, javascript y casi todo lo que se incluye en un navegador. El artesano contemporáneo está decidido a disminuir el marcado, incorporar estilos consistentes y producir un código funcional elegante para la estabilidad del producto a largo plazo."
  },
  contactMe: {
    en: "Contact Me",
    pl: "ontactcay emay",
    zn: "联络我",
    lt: "c0n74c7 m3",
    es: "Contáctame"
  },
  "jobs.vitalsource": {
    en:
      "Vitalsource is the leader in digital textbooks recieving over 1 million unique visitors per month. As an engineer here, tasks range from: collaborating with design, building new features, bug fixes, and making sure our application is accessible. I work primarily as a front-end engineer using React.Js, but ocassionally make contributions to the Rails back-end API as well."
  },
  "jobs.tapmojo": {
    en:
      "Social media manager and admin for the now shutdown mobile application, GifBoom. I was in charge of all out-facing media for the company. This included social media networks, technical writing, page/email copy, and customer support."
  },
  "jobs.rementor": {
    en:
      "Re-designed much of their legacy codebase. This included CMS integration, front-end pages/funnels, wordpress re-design, and a newly implements shopping-cart system."
  }
};
