import React, { Component } from "react";
import styled from "styled-components";
import OceanExplorer from "../icons/oceanexplorer.png";
import Experience from "./experience";
import Education from "./education";
import Hobbies from "./hobbies";
import Skills from "./skills";
import Other from "./other";
import LinkedIn from "../icons/linkedin";
import Email from "../icons/envelope";
import GitHub from "../icons/github";
import { Links as LinksList } from "../page-data";
import NakedButton from "./naked-button";
import MediaQuery from "react-responsive";
import Responsive from "react-responsive";
import MobileResume from "./MobileVersion";
import { Provider, Translate, Translator } from "react-translated";
import translation from "../translations/translation";
import FooterOptions from "./footer";

const Resume = styled.div`
  width: 100%;
  min-height: 500px;
  background: #6d214f;
  color: #ffffff;
  display: flex;
  a {
    text-decoration: none;
    color: inherit;
  }
  .strong {
    font-weight: 600;
  }
`;

const LinkList = styled.div`
  margin: 3em 0;
`;

const RightColumn = styled.div`
  float: right;
  width: 65%;
  background: #ecf0f1;
  color: #34495e;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`;
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width: 35%;
  background: #3498db;
  color: #ecf0f1;

  ul {
    max-width: 85%;
    padding: 0;
    list-style: none;
    li {
      padding: 5px 0;
      div {
        font-size: 0.9em;
      }
    }
  }
  .ocean-explorer {
    width: 100%;
  }
  a {
    color: white;
  }
  footer {
    text-align: center;
    margin-top: auto;
    margin-bottom: 1em;
  }
`;

const NameBlock = styled.div`
  font-weight: 600;
  display: flex;
  flex-direction: column;
  .name {
    margin: 0.5em 0;
    font-size: 2.5em;
  }
  .jobTitle {
    font-size: 1.8em;
    margin-bottom: 0.5em;
  }
`;

const ContactMe = styled.div`
  margin: 1em 0;
`;
const TopBlock = styled.div`
  background-color: #006c94;
  padding: 20px;
  position: relative;
  background-color: #006c94;
  padding-bottom: 40%;
  #slant {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 10vw;
    fill: #3498db;
  }
`;
const BottomBlock = styled.div`
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  h3 {
    padding-top: 0;
    margin-top: 0;
  }
`;
const FollowMe = styled.div`
  margin: 2em 0 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 1em;
    * {
      fill: white;
    }
  }
`;

const MobileQuery = props => <Responsive {...props} maxWidth={640} />;

class Page extends Component {
  state = { locale: "en" };
  render() {
    return (
      <Provider language={this.state.locale} translation={translation}>
        <MobileQuery>
          <MobileResume />
        </MobileQuery>
        <MediaQuery minWidth={640}>
          <Resume className="Page">
            <LeftColumn>
              <TopBlock>
                <img
                  src={OceanExplorer}
                  className="ocean-explorer"
                  alt="Joshua Toy"
                  width={"200px"}
                />
                <NameBlock>
                  <div className="name">Joshua S. Toy</div>
                  <div className="jobTitle">
                    <Translate text="jobTitle" />
                  </div>
                </NameBlock>
                <div>
                  <Translate text="bio" />
                </div>
                <svg
                  id="slant"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                >
                  <polygon points="0,100 0,0 100,100" />
                </svg>
              </TopBlock>
              <BottomBlock>
                <ContactMe>
                  <h2>
                    <Translate text="contactMe" />
                  </h2>
                  <div>424-242-4009</div>
                  <div>Melrose, Massachusetts</div>
                  <div>www.JoshuaToy.com</div>
                  <a
                    href="mailto:joshua@jtoy.org?Subject=Hello%20Josh!"
                    target="_top"
                  >
                    Joshua@JToy.org
                  </a>
                </ContactMe>
                <LinkList>
                  <h3>Links</h3>
                  <ul>
                    {Object.keys(LinksList).map((Link, i) => {
                      const currentLink = LinksList[Link];
                      return (
                        <li key={currentLink.name}>
                          <NakedButton href={currentLink.href}>
                            {currentLink.name}
                          </NakedButton>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: currentLink.description
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </LinkList>

                <FooterOptions
                  changeLocale={locale => {
                    this.setState({ locale: locale });
                  }}
                />
              </BottomBlock>
            </LeftColumn>
            <RightColumn>
              <Experience />
              <Education />
              <Skills />
              <Other width={"50%"} />
              <Hobbies width={"50%"} />
            </RightColumn>
          </Resume>
        </MediaQuery>
      </Provider>
    );
  }
}

export default Page;
